import React from "react";
import { graphql } from "gatsby";

import { Layout, Loader, PropertyListings } from "components";

import "./property-list.scss";

const PropertyList = ({ data, pageContext, location }) => {
  const {
    itemsPerPage,
    currentPageNumber,
    numberOfPages,
    propertiesOnPage = [],
    defaultListTitle,
    defaultListSubTitle,
    totalNumberOfProperties,
    primaryCategory,
    startIndex,
    endIndex,
  } = pageContext;

  const { wpPage } = data;

  const shouldDisplayPageData =
    wpPage !== undefined &&
    wpPage?.acfLayout?.layout?.find((layout) => layout?.fieldGroupName?.includes("PropertyListings")) !== undefined;

  return (
    <Layout
      pageTitle={wpPage?.title || "Properties | Davey Real Estate"}
      className="dark"
      layoutType="property-list"
      primaryPropertyCategory={primaryCategory}
      seo={wpPage?.seo}
    >
      {shouldDisplayPageData ? (
        wpPage?.acfLayout?.layout?.length > 0 ? (
          <>
            <Loader
              location={location}
              data={wpPage?.acfLayout.layout.map(({ ...layout }) => ({
                ...layout,
                enableBreadcrumbs: true,
                listTitle: defaultListTitle,
                listSubTitle: defaultListSubTitle,
                properties: propertiesOnPage,
                numberOfProperties: totalNumberOfProperties,
                propertiesPerPage: itemsPerPage,
                currentPageNumber,
                numberOfPages,
                startIndex,
                endIndex,
              }))}
            />
          </>
        ) : (
          <PropertyListings
            location={location}
            data={{
              enableBreadcrumbs: true,
              listTitle: defaultListTitle,
              listSubTitle: defaultListSubTitle,
              properties: propertiesOnPage,
              numberOfProperties: totalNumberOfProperties,
              propertiesPerPage: itemsPerPage,
              currentPageNumber,
              numberOfPages,
              startIndex,
              endIndex,
            }}
          />
        )
      ) : (
        <PropertyListings
          location={location}
          data={{
            enableBreadcrumbs: true,
            listTitle: defaultListTitle,
            listSubTitle: defaultListSubTitle,
            properties: propertiesOnPage,
            numberOfProperties: totalNumberOfProperties,
            propertiesPerPage: itemsPerPage,
            currentPageNumber,
            numberOfPages,
            startIndex,
            endIndex,
          }}
        />
      )}
    </Layout>
  );
};

export default PropertyList;

export const query = graphql`
  query PropertyListQuery($uri: String) {
    wpPage(uri: { eq: $uri }) {
      id
      title
      uri
      ...SEOPageQuery
      acfLayout {
        layout {
          ...PropertyListingsQuery
          ...CardBlockQuery
          ...ImageAndContentBlockQuery
          ...GlobalImageAndTextQuery
        }
      }
    }
  }
`;
